import React, { Component } from 'react';

import cx from 'classnames';
import { curlyQuotes } from 'utils/text';
import getTeaserLink from 'utils/getTeaserLink';
import get from 'lodash/get';

import { Button, Img } from 'components/base';
import AuthorButtons from 'components/AuthorButtons';
import SectionButton from 'components/SectionButton';

import Language from 'constants/Language';

import { ArticleLink, FeatureArticlePageLink } from 'sharedTypes';
import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';

interface Props {
  className?: string;
  article: ArticleLink | FeatureArticlePageLink;
  variant: 'small' | 'medium' | 'large' | 'x-large';
  hideSectionIcon?: boolean;
  isSplitLayout?: boolean;
}

class ImageTextIntroStoryTeaserWidget extends Component<Props> {
  render() {
    const {
      className = '',
      article,
      variant,
      hideSectionIcon,
      isSplitLayout,
    } = this.props;
    const image = article.featuredImage;
    const heroImage = article.heroImage;
    const mobileHeroImage = article.mobileHeroImage;
    const useFeatureArticleHeroImage =
      article._type === 'featureArticlePage' && article.useArticleHeaderHero;
    const title = get(article, 'title', '');
    const isFiction = get(article, 'isFiction', false);
    const brief = get(article, 'brief');
    const dek = get(article, 'dek', '');
    const hasSection = 'section' in article && article.section !== undefined;
    const isFeatureArticle =
      get(article, '_type', 'featureArticlePage') === 'featureArticlePage';

    return (
      <div
        className={cx(
          `ImageTextIntroStoryTeaserWidget ImageTextIntroStoryTeaserWidget--${variant} teaser-widget-container color-black flex flex-col`,
          className,
          {
            'justify-center': variant === 'small',
            'split-layout ImageTextIntroStoryTeaserWidget--split-layout':
              isSplitLayout,
            'fiction-view fiction-view--more-padded': isFiction,
          }
        )}
      >
        <div
          className={cx('ImageTextIntroStoryTeaserWidget__container', {
            'md:mx1_5': variant === 'x-large' || variant === 'large',
          })}
        >
          {!useFeatureArticleHeroImage && image && image.src && (
            <Button
              ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                title: title,
              })}
              to={getTeaserLink(article)}
              className="h100 w100"
              containerClassName={cx(
                'ImageTextStoryTeaserWidget__image relative w100',
                {
                  'fiction-teaser-img': isFiction,
                }
              )}
              wrap={true}
              forceInternalLink={isFeatureArticle}
            >
              <Img
                className="grayscale-img w100 radius-xs"
                alt={image.alt || image.caption || ''}
                src={sanityImgUtil(image, 1080)}
                srcSet={sanityImgSrcSetUtil(image, 320, 768, 1080)}
                sizes="(maxWidth: 400px) 320px, (maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px"
                dimensions={image.metadata && image.metadata.dimensions}
                crop={image.crop}
              />
              <Img
                className="color-img absolute t0 b0 r0 l0 w100 radius-xs"
                alt={image.alt || image.caption || ''}
                src={sanityImgUtil(image, 1080)}
                srcSet={sanityImgSrcSetUtil(image, 320, 768, 1080)}
                dimensions={image.metadata && image.metadata.dimensions}
                sizes="(maxWidth: 400px) 320px, (maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px"
              />
            </Button>
          )}
          {useFeatureArticleHeroImage &&
            mobileHeroImage &&
            mobileHeroImage.src && (
              <Button
                ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                  title: title,
                })}
                to={getTeaserLink(article)}
                className="h100 w100"
                containerClassName={cx(
                  'ImageTextStoryTeaserWidget__image relative w100 block md:none',
                  {
                    'fiction-teaser-img': isFiction,
                  }
                )}
                wrap={true}
                forceInternalLink={isFeatureArticle}
              >
                <Img
                  className="grayscale-img w100 radius-xs"
                  alt={mobileHeroImage.alt || mobileHeroImage.caption || ''}
                  src={sanityImgUtil(mobileHeroImage, 1080)}
                  srcSet={sanityImgSrcSetUtil(mobileHeroImage, 320, 768, 1080)}
                  sizes="(maxWidth: 400px) 320px, (maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px"
                  dimensions={
                    mobileHeroImage.metadata &&
                    mobileHeroImage.metadata.dimensions
                  }
                  crop={mobileHeroImage.crop}
                />
                <Img
                  className="color-img absolute t0 b0 r0 l0 w100 radius-xs"
                  alt={mobileHeroImage.alt || mobileHeroImage.caption || ''}
                  src={sanityImgUtil(mobileHeroImage, 1080)}
                  srcSet={sanityImgSrcSetUtil(mobileHeroImage, 320, 768, 1080)}
                  dimensions={
                    mobileHeroImage.metadata &&
                    mobileHeroImage.metadata.dimensions
                  }
                  sizes="(maxWidth: 400px) 320px, (maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px"
                />
              </Button>
            )}
          {useFeatureArticleHeroImage && heroImage && heroImage.src && (
            <Button
              ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                title: title,
              })}
              to={getTeaserLink(article)}
              className="h100 w100"
              containerClassName={cx(
                'ImageTextStoryTeaserWidget__image relative w100 none md:block',
                {
                  'fiction-teaser-img': isFiction,
                }
              )}
              wrap={true}
              forceInternalLink={isFeatureArticle}
            >
              <Img
                className="grayscale-img w100 radius-xs"
                alt={heroImage.alt || heroImage.caption || ''}
                src={sanityImgUtil(heroImage, 1080)}
                srcSet={sanityImgSrcSetUtil(heroImage, 320, 768, 1080)}
                sizes="(maxWidth: 400px) 320px, (maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px"
                dimensions={heroImage.metadata && heroImage.metadata.dimensions}
                crop={heroImage.crop}
              />
              <Img
                className="color-img absolute t0 b0 r0 l0 w100 radius-xs"
                alt={heroImage.alt || heroImage.caption || ''}
                src={sanityImgUtil(heroImage, 1080)}
                srcSet={sanityImgSrcSetUtil(heroImage, 320, 768, 1080)}
                dimensions={heroImage.metadata && heroImage.metadata.dimensions}
                sizes="(maxWidth: 400px) 320px, (maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px"
              />
            </Button>
          )}
          {!useFeatureArticleHeroImage && hasSection && article.section && (
            <SectionButton
              className="ImageTextIntroStoryTeaserWidget__section-button"
              section={article.section}
              hideIcon={hideSectionIcon || !get(article, 'section.title')}
              isFictionTeaser={isFiction}
            />
          )}
          {!useFeatureArticleHeroImage && (
            <Button
              ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                title: title,
              })}
              to={getTeaserLink(article)}
              className="h100 w100 color-black"
              containerClassName={cx(
                'ImageTextIntroStoryTeaserWidget__title itc-cushing block-important',
                {
                  'font-600': variant === 'small' || variant === 'medium',
                  'font-300': variant === 'x-large' || variant === 'large',
                }
              )}
              wrap={true}
              forceInternalLink={isFeatureArticle}
            >
              <span
                className={cx({
                  'linked-border-bottom-sm-on-hover-red':
                    variant === 'small' || variant === 'medium',
                  'linked-border-bottom-md-on-hover-red':
                    variant === 'x-large' || variant === 'large',
                })}
              >
                {curlyQuotes(title)}
              </span>
            </Button>
          )}
          {!useFeatureArticleHeroImage &&
            (article.brief || article.dek) &&
            (variant === 'medium' ||
              variant === 'large' ||
              variant === 'x-large') && (
              <Button
                ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                  title: title,
                })}
                to={getTeaserLink(article)}
                wrap={true}
                containerClassName="block-important"
                forceInternalLink={isFeatureArticle}
              >
                <p
                  className={cx(
                    'ImageTextIntroStoryTeaserWidget__dek color-gray-darker graebenbach font-400'
                  )}
                >
                  {curlyQuotes(!!brief ? brief : dek)}
                </p>
              </Button>
            )}
          {!useFeatureArticleHeroImage && (
            <AuthorButtons
              className={cx('ImageTextIntroStoryTeaserWidget__authors', {
                'ImageTextIntroStoryTeaserWidget__authors--small':
                  variant === 'small',
              })}
              authors={get(article, 'authors', [])}
            />
          )}
          <Button
            ariaLabel={Language.t('Global.articleButtonAriaLabel', {
              title: title,
            })}
            to={getTeaserLink(article)}
            containerClassName="ImageTextIntroStoryTeaserWidget__intro-container w100"
            wrap={true}
            forceInternalLink={isFeatureArticle}
          >
            <div
              className={
                'ImageTextIntroStoryTeaserWidget__body-container relative dotted-border-black color-black flex flex-col items-start sm:block'
              }
            >
              <p className="ImageTextIntroStoryTeaserWidget__body graebenbach text-section-body-sm font-400">
                {curlyQuotes(`${get(article, 'excerpt', '')}`)}
              </p>

              <p className="ImageTextIntroStoryTeaserWidget__continue-reading relative color-black graebenbach text-section-body-sm font-400 inline-block mt1_75">
                {Language.t(
                  'Widgets.FeaturedStoryTeaserWidget.continueReading'
                )}{' '}
                &rarr;&#xFE0E;
              </p>
            </div>
          </Button>
        </div>
      </div>
    );
  }
}

export default ImageTextIntroStoryTeaserWidget;
