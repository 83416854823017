import React from 'react';

import cx from 'classnames';
import { curlyQuotes } from 'utils/text';
import sanityImgUtil from 'utils/sanityImgUtil';
import sanityImgSrcSetUtil from 'utils/sanityImgSrcSetUtil';
import getTeaserLink from 'utils/getTeaserLink';
import get from 'lodash/get';

import { Button, Img } from 'components/base';
import AuthorButtons from 'components/AuthorButtons';
import SectionButton from 'components/SectionButton';
import Language from 'constants/Language';

import { ArticleLink, FeatureArticlePageLink } from 'sharedTypes';

interface Props {
  article: ArticleLink | FeatureArticlePageLink;
  variant:
    | 'x-small'
    | 'small'
    | 'medium'
    | 'large'
    | 'large-without-image'
    | 'x-large'
    | 'xx-large';
  className?: string;
  hideSectionIcon?: boolean;
  isSplitLayout?: boolean;
}

const variantsWithImage: Props['variant'][] = [
  'medium',
  'large',
  'x-large',
  'xx-large',
];

const FeaturedStoryTeaserWidget: React.FC<Props> = ({
  article,
  variant,
  className = '',
  hideSectionIcon,
  isSplitLayout,
}) => {
  const { heroImage } = article;
  const image = article.featuredImage;
  const mobileHeroImage = article.mobileHeroImage;
  const teaserHasImg = variantsWithImage.includes(variant);
  const useFeatureArticleHeroImage =
    article._type === 'featureArticlePage' && article.useArticleHeaderHero;
  const title = get(article, 'title', '');
  const isFiction = get(article, 'isFiction', false);
  const brief = get(article, 'brief');
  const dek = get(article, 'dek', '');
  const hasSection = 'section' in article && article.section !== undefined;
  const isFeatureArticle =
    get(article, '_type', 'featureArticlePage') === 'featureArticlePage';

  return (
    <div
      className={cx(
        `FeaturedStoryTeaserWidget FeaturedStoryTeaserWidget--${variant} teaser-widget-container color-black`,
        className,
        {
          'lg:flex lg:flex-col':
            variant === 'xx-large' ||
            variant === 'large' ||
            variant === 'large-without-image' ||
            variant === 'medium',
          'lg:flex lg:flex-row': variant === 'x-large',
          'split-layout FeaturedStoryTeaserWidget--split-layout': isSplitLayout,
          'fiction-view fiction-view--more-padded': isFiction,
        }
      )}
    >
      {teaserHasImg && (
        <>
          {!useFeatureArticleHeroImage && image?.src ? (
            <Button
              ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                title: title,
              })}
              to={getTeaserLink(article)}
              className="h100 w100"
              containerClassName={cx(
                'FeaturedStoryTeaserWidget__image w100 relative',
                {
                  'lg:col-6': variant === 'x-large',
                  'lg:col-12': variant !== 'x-large',
                  'fiction-teaser-img': isFiction,
                }
              )}
              wrap={true}
              forceInternalLink={isFeatureArticle}
            >
              <Img
                className={cx('grayscale-img w100 fit-cover radius-xs', {
                  h100: variant === 'x-large',
                })}
                alt={image.alt || image.credit || ''}
                sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px"
                src={sanityImgUtil(image, 1920)}
                srcSet={sanityImgSrcSetUtil(image, 768, 1080, 1920)}
                dimensions={image.metadata?.dimensions}
                crop={image.crop}
              />
              <Img
                className={cx(
                  'color-img absolute t0 b0 r0 l0 w100 fit-cover radius-xs',
                  { h100: variant === 'x-large' }
                )}
                alt={image.alt || image.credit || ''}
                sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px"
                src={sanityImgUtil(image, 1920)}
                srcSet={sanityImgSrcSetUtil(image, 768, 1080, 1920)}
                dimensions={image.metadata?.dimensions}
              />
            </Button>
          ) : (
            <>
              {mobileHeroImage?.src && (
                <Button
                  ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                    title: title,
                  })}
                  to={getTeaserLink(article)}
                  className="h100 w100 block md:none"
                  containerClassName={cx(
                    'FeaturedStoryTeaserWidget__image w100 relative',
                    {
                      'lg:col-6': variant === 'x-large',
                      'lg:col-12': variant !== 'x-large',
                      'fiction-teaser-img': isFiction,
                    }
                  )}
                  wrap={true}
                  forceInternalLink={isFeatureArticle}
                >
                  <Img
                    className={cx('grayscale-img w100 fit-cover radius-xs', {
                      h100: variant === 'x-large',
                    })}
                    alt={mobileHeroImage.alt || mobileHeroImage.credit || ''}
                    sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px"
                    src={sanityImgUtil(mobileHeroImage, 1920)}
                    srcSet={sanityImgSrcSetUtil(
                      mobileHeroImage,
                      768,
                      1080,
                      1920
                    )}
                    dimensions={mobileHeroImage.metadata?.dimensions}
                    crop={mobileHeroImage.crop}
                  />
                  <Img
                    className={cx(
                      'color-img absolute t0 b0 r0 l0 w100 fit-cover radius-xs',
                      { h100: variant === 'x-large' }
                    )}
                    alt={mobileHeroImage.alt || mobileHeroImage.credit || ''}
                    sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px"
                    src={sanityImgUtil(mobileHeroImage, 1920)}
                    dimensions={mobileHeroImage.metadata?.dimensions}
                    srcSet={sanityImgSrcSetUtil(
                      mobileHeroImage,
                      768,
                      1080,
                      1920
                    )}
                  />
                </Button>
              )}
              {heroImage?.src && (
                <Button
                  ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                    title: title,
                  })}
                  to={getTeaserLink(article)}
                  className="h100 w100 "
                  containerClassName={cx(
                    'FeaturedStoryTeaserWidget__image w100 relative',
                    {
                      'lg:col-6': variant === 'x-large',
                      'lg:col-12': variant !== 'x-large',
                      'fiction-teaser-img': isFiction,
                      'none md:block': mobileHeroImage?.src,
                      block: !mobileHeroImage?.src,
                    }
                  )}
                  wrap={true}
                  forceInternalLink={isFeatureArticle}
                >
                  <Img
                    className={cx('grayscale-img w100 fit-cover radius-xs', {
                      h100: variant === 'x-large',
                    })}
                    alt={heroImage.alt || heroImage.credit || ''}
                    sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px"
                    src={sanityImgUtil(heroImage, 1920)}
                    srcSet={sanityImgSrcSetUtil(heroImage, 768, 1080, 1920)}
                    dimensions={heroImage.metadata?.dimensions}
                    crop={heroImage.crop}
                  />
                  <Img
                    className={cx(
                      'color-img absolute t0 b0 r0 l0 w100 fit-cover radius-xs',
                      { h100: variant === 'x-large' }
                    )}
                    alt={heroImage.alt || heroImage.credit || ''}
                    sizes="(maxWidth: 768px) 768px, (maxWidth: 1080px) 1080px"
                    src={sanityImgUtil(heroImage, 1920)}
                    dimensions={heroImage.metadata?.dimensions}
                    srcSet={sanityImgSrcSetUtil(heroImage, 768, 1080, 1920)}
                  />
                </Button>
              )}
            </>
          )}
        </>
      )}

      <div
        className={cx(
          'FeaturedStoryTeaserWidget__container flex flex-col items-center',
          {
            'lg:pl5 lg:col-6': variant === 'x-large',
          }
        )}
      >
        {!useFeatureArticleHeroImage && hasSection && article.section && (
          <SectionButton
            className="FeaturedStoryTeaserWidget__section-button"
            section={article.section}
            hideIcon={hideSectionIcon || !article.section.title}
            isFictionTeaser={isFiction}
          />
        )}
        {!useFeatureArticleHeroImage && (
          <>
            <Button
              ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                title: title,
              })}
              to={getTeaserLink(article)}
              containerClassName="FeaturedStoryTeaserWidget__title-container"
              className="text-center"
              wrap={true}
              forceInternalLink={isFeatureArticle}
            >
              <span
                className={cx(
                  'FeaturedStoryTeaserWidget__title text-center itc-cushing font-300 color-black transition linked-border-bottom-lg-on-hover-red'
                )}
              >
                {curlyQuotes(title)}
              </span>
            </Button>
            <Button
              ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                title: title,
              })}
              to={getTeaserLink(article)}
              wrap={true}
              containerClassName="FeaturedStoryTeaserWidget__dek-container"
              forceInternalLink={isFeatureArticle}
            >
              <p className="FeaturedStoryTeaserWidget__dek color-gray-darker text-center graebenbach font-400 text-center">
                {curlyQuotes(!!brief ? brief : dek)}
              </p>
            </Button>
            <AuthorButtons
              className="FeaturedStoryTeaserWidget__authors text-center"
              authors={get(article, 'authors', [])}
            />
          </>
        )}
        <Button
          ariaLabel={Language.t('Global.articleButtonAriaLabel', {
            title: title,
          })}
          to={getTeaserLink(article)}
          containerClassName="FeaturedStoryTeaserWidget__intro-container w100"
          wrap={true}
          forceInternalLink={isFeatureArticle}
        >
          <div
            className={cx(
              'FeaturedStoryTeaserWidget__body-container relative dotted-border-black color-black flex flex-col items-start sm:block',
              {
                'FeaturedStoryTeaserWidget__body-container--two-column':
                  variant === 'x-large' ||
                  variant === 'medium' ||
                  variant === 'small',
                'FeaturedStoryTeaserWidget__body-container--three-column':
                  variant === 'large' || variant === 'large-without-image',
                'FeaturedStoryTeaserWidget__body-container--four-column':
                  variant === 'xx-large',
                'lg:flex lg:flex-col lg:items-center': variant === 'x-small',
                'fiction-teaser-border': isFiction,
              }
            )}
          >
            <p className="FeaturedStoryTeaserWidget__body graebenbach text-section-body-sm font-400">
              {curlyQuotes(`${get(article, 'excerpt', '')}...`)}
            </p>

            <p className="FeaturedStoryTeaserWidget__continue-reading relative color-black graebenbach text-section-body-sm font-400 inline-block mt1_75">
              {Language.t('Widgets.FeaturedStoryTeaserWidget.continueReading')}{' '}
              &rarr;&#xFE0E;
            </p>
          </div>
        </Button>
      </div>
    </div>
  );
};

export default FeaturedStoryTeaserWidget;
