import React from 'react';
import { PortableText } from '@portabletext/react';
import { curlyQuotes } from '../utils/text';

interface BlockContentProps {
  blocks: any;
  serializers?: any;
}

const fixBlockChild = (child: any) => {
  if (child.text) {
    return {
      ...child,
      text: curlyQuotes(child.text),
      children: child.children ? child.children.map(fixBlockChild) : undefined,
    };
  }
  return {
    ...child,
    children: child.children ? child.children.map(fixBlockChild) : undefined,
  };
};

const fixBlocks = (block: any) => {
  return {
    ...block,
    children: block.children ? block.children.map(fixBlockChild) : undefined,
  };
};

export const BlockContent: React.FC<BlockContentProps> = ({
  blocks,
  serializers,
}) => {
  if (!blocks || blocks.length === 0) return null;

  const fixedBlocks = blocks.map(fixBlocks);

  return <PortableText value={fixedBlocks} components={serializers} />;
};
