import React from 'react';
import { Button } from './base';

const FictionTag: React.FC = () => {
  return (
    <Button
      to="/tags/tablet-original-fiction"
      className="fiction-tag-container itc-cushing"
    >
      <span className="fiction-tag-text">FICTION</span>
    </Button>
  );
};

export default FictionTag;
