import React, { Component } from 'react';

import cx from 'classnames';
import { curlyQuotes } from 'utils/text';
import getTeaserLink from 'utils/getTeaserLink';
import get from 'lodash/get';

import { Button } from 'components/base';
import AuthorButtons from 'components/AuthorButtons';
import SectionButton from 'components/SectionButton';

import Language from 'constants/Language';

import { ArticleLink, FeatureArticlePageLink } from 'sharedTypes';

interface Props {
  className?: string;
  article: ArticleLink | FeatureArticlePageLink;
  variant: 'small' | 'medium' | 'large' | 'x-large';
  hideSectionIcon?: boolean;
  isSplitLayout?: boolean;
}

class TextIntroStoryTeaserWidget extends Component<Props> {
  render() {
    const {
      className = '',
      article,
      variant,
      hideSectionIcon,
      isSplitLayout,
    } = this.props;
    const title = get(article, 'title', '');
    const isFiction = get(article, 'isFiction', false);
    const brief = get(article, 'brief');
    const dek = get(article, 'dek', '');
    const isFeatureArticle =
      get(article, '_type', 'featureArticlePage') === 'featureArticlePage';
    const hasSection = 'section' in article && article.section !== undefined;

    return (
      <div
        className={cx(
          `TextIntroStoryTeaserWidget TextIntroStoryTeaserWidget--${variant} teaser-widget-container color-black flex flex-col`,
          className,
          {
            'justify-center': variant === 'small',
            'split-layout TextIntroStoryTeaserWidget--split-layout':
              isSplitLayout,
            'fiction-view fiction-view--more-padded': isFiction,
          }
        )}
      >
        <div
          className={cx('TextIntroStoryTeaserWidget__container', {
            'md:mx1_5': variant === 'x-large' || variant === 'large',
          })}
        >
          {hasSection && article.section && (
            <SectionButton
              className="TextIntroStoryTeaserWidget__section-button"
              section={article.section}
              hideIcon={hideSectionIcon || !get(article, 'section.title')}
              isFictionTeaser={isFiction}
            />
          )}
          <Button
            ariaLabel={Language.t('Global.articleButtonAriaLabel', {
              title: title,
            })}
            to={getTeaserLink(article)}
            className="h100 w100 color-black"
            containerClassName={cx(
              'TextIntroStoryTeaserWidget__title itc-cushing block-important',
              {
                'font-600': variant === 'small' || variant === 'medium',
                'font-300': variant === 'x-large' || variant === 'large',
              }
            )}
            wrap={true}
            forceInternalLink={isFeatureArticle}
          >
            <span
              className={cx({
                'linked-border-bottom-sm-on-hover-red':
                  variant === 'small' || variant === 'medium',
                'linked-border-bottom-md-on-hover-red':
                  variant === 'x-large' || variant === 'large',
              })}
            >
              {curlyQuotes(title)}
            </span>
          </Button>
          {(article.brief || article.dek) &&
            (variant === 'medium' ||
              variant === 'large' ||
              variant === 'x-large') && (
              <Button
                ariaLabel={Language.t('Global.articleButtonAriaLabel', {
                  title: title,
                })}
                to={getTeaserLink(article)}
                wrap={true}
                containerClassName="block-important"
                forceInternalLink={isFeatureArticle}
              >
                <p
                  className={cx(
                    'TextIntroStoryTeaserWidget__dek color-gray-darker graebenbach font-400'
                  )}
                >
                  {curlyQuotes(!!brief ? brief : dek)}
                </p>
              </Button>
            )}
          <AuthorButtons
            className={cx('TextIntroStoryTeaserWidget__authors', {
              'TextIntroStoryTeaserWidget__authors--small': variant === 'small',
            })}
            authors={get(article, 'authors', [])}
          />
          <Button
            ariaLabel={Language.t('Global.articleButtonAriaLabel', {
              title: title,
            })}
            to={getTeaserLink(article)}
            containerClassName="TextIntroStoryTeaserWidget__intro-container w100"
            wrap={true}
            forceInternalLink={isFeatureArticle}
          >
            <div
              className={
                'TextIntroStoryTeaserWidget__body-container relative dotted-border-black color-black flex flex-col items-start sm:block'
              }
            >
              <p className="TextIntroStoryTeaserWidget__body graebenbach text-section-body-sm font-400">
                {curlyQuotes(`${get(article, 'excerpt', '')}`)}
              </p>

              <p className="TextIntroStoryTeaserWidget__continue-reading relative color-black graebenbach text-section-body-sm font-400 inline-block mt1_75">
                {Language.t(
                  'Widgets.FeaturedStoryTeaserWidget.continueReading'
                )}{' '}
                &rarr;&#xFE0E;
              </p>
            </div>
          </Button>
        </div>
      </div>
    );
  }
}

export default TextIntroStoryTeaserWidget;
