import imageBuilder from 'utils/imageBuilder';

import { Image } from 'sharedTypes';

//Note: Update DPR based off window.devicePixelRatio
const Defaults = {
  WIDTH: 1280,
  QUALITY: 70,
  DPR: 1,
  OPTIONS: {},
};

const sanityImgUtil = (
  image: Image,
  width = Defaults.WIDTH,
  options = Defaults.OPTIONS,
  quality = Defaults.QUALITY,
  isHighDefImage = false
): string => {
  if (!image?.src) return '';
  if (image.src.startsWith('/')) {
    return image.src;
  }

  const dpr = typeof window !== 'undefined' ? window.devicePixelRatio || Defaults.DPR : Defaults.DPR;
  
  const dynamicWidth = isHighDefImage && typeof window !== 'undefined' ? window.innerWidth : width;

  const imageUrl = imageBuilder(image, dynamicWidth, quality, dpr, isHighDefImage);

  const optionSets: string[][] = Object.entries(options);

  if (optionSets.length) {
    const optionsString = optionSets
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    return `${imageUrl}&${optionsString}`;
  } else {
    return imageUrl;
  }
};

export default sanityImgUtil;
