import React from 'react';

import FactStoryTeaserWidget from 'components/storyTeasers/FactStoryTeaserWidget';
import FeaturedStoryTeaserWidget from 'components/storyTeasers/FeaturedStoryTeaserWidget';
import ImageTextStoryTeaserWidget from 'components/storyTeasers/ImageTextStoryTeaserWidget';
import QuoteStoryTeaserWidget from 'components/storyTeasers/QuoteStoryTeaserWidget';
import TextStoryTeaserWidget from 'components/storyTeasers/TextStoryTeaserWidget';
import ImageLightboxStoryTeaserWidget from 'components/storyTeasers/ImageLightboxStoryTeaserWidget';

import { ArticleLink, FeatureArticlePageLink, StoryTeaser } from 'sharedTypes';
import TextIntroStoryTeaserWidget from './storyTeasers/TextIntroStoryTeaserWidget';
import ImageTextIntroStoryTeaserWidget from './storyTeasers/ImageTextIntroStoryTeaserWidget';

interface Props {
  className?: string;
  teaser: StoryTeaser;
  article?: ArticleLink | FeatureArticlePageLink;
  hideSectionIcon?: boolean;
  isSplitLayout?: boolean;
  isCarouselTeaser?: boolean;
}

const StoryTeaserSwitch: React.FC<Props> = ({
  className = '',
  teaser,
  article,
  hideSectionIcon,
  isSplitLayout,
  isCarouselTeaser,
}) => {
  switch (teaser) {
    case 'teaser-fact':
      if (!article) return null;

      return (
        <FactStoryTeaserWidget
          className={className}
          article={article}
          isSplitLayout={isSplitLayout}
        />
      );
    case 'teaser-feature-xs':
      if (!article) return null;

      return (
        <FeaturedStoryTeaserWidget
          className={className}
          article={article}
          variant="x-small"
          hideSectionIcon={hideSectionIcon}
          isSplitLayout={isSplitLayout}
        />
      );
    case 'teaser-feature-sm':
      if (!article) return null;

      return (
        <FeaturedStoryTeaserWidget
          className={className}
          article={article}
          variant="small"
          hideSectionIcon={hideSectionIcon}
          isSplitLayout={isSplitLayout}
        />
      );
    case 'teaser-feature-md':
      if (!article) return null;

      return (
        <FeaturedStoryTeaserWidget
          className={className}
          article={article}
          variant="medium"
          hideSectionIcon={hideSectionIcon}
          isSplitLayout={isSplitLayout}
        />
      );
    case 'teaser-feature-lg':
      if (!article) return null;

      return (
        <FeaturedStoryTeaserWidget
          className={className}
          article={article}
          variant="large"
          hideSectionIcon={hideSectionIcon}
          isSplitLayout={isSplitLayout}
        />
      );
    case 'teaser-feature-lg-no-image':
      if (!article) return null;

      return (
        <FeaturedStoryTeaserWidget
          className={className}
          article={article}
          variant="large-without-image"
          hideSectionIcon={hideSectionIcon}
          isSplitLayout={isSplitLayout}
        />
      );
    case 'teaser-feature-xl':
      if (!article) return null;

      return (
        <FeaturedStoryTeaserWidget
          className={className}
          article={article}
          variant="x-large"
          hideSectionIcon={hideSectionIcon}
          isSplitLayout={isSplitLayout}
        />
      );
    case 'teaser-feature-xxl':
      if (!article) return null;

      return (
        <FeaturedStoryTeaserWidget
          className={className}
          article={article}
          variant="xx-large"
          hideSectionIcon={hideSectionIcon}
          isSplitLayout={isSplitLayout}
        />
      );
    case 'teaser-image-text-sm':
      if (!article) return null;

      return (
        <ImageTextStoryTeaserWidget
          className={className}
          variant="small"
          article={article}
          hideSectionIcon={hideSectionIcon}
          isSplitLayout={isSplitLayout}
          isCarouselTeaser={isCarouselTeaser}
        />
      );
    case 'teaser-image-text-md':
      if (!article) return null;

      return (
        <ImageTextStoryTeaserWidget
          className={className}
          variant="medium"
          article={article}
          hideSectionIcon={hideSectionIcon}
          isSplitLayout={isSplitLayout}
        />
      );
    case 'teaser-image-text-lg':
      if (!article) return null;

      return (
        <ImageTextStoryTeaserWidget
          className={className}
          variant="large"
          article={article}
          hideSectionIcon={hideSectionIcon}
          isSplitLayout={isSplitLayout}
        />
      );
    case 'teaser-image-lightbox':
      if (!article) return null;

      return (
        <ImageLightboxStoryTeaserWidget
          className={className}
          article={article}
        />
      );
    case 'teaser-quote':
      if (!article) return null;

      return (
        <QuoteStoryTeaserWidget
          className={className}
          article={article}
          isSplitLayout={isSplitLayout}
        />
      );
    case 'teaser-text-sm':
      if (!article) return null;

      return (
        <TextStoryTeaserWidget
          variant="small"
          className={className}
          article={article}
          hideSectionIcon={hideSectionIcon}
          isSplitLayout={isSplitLayout}
        />
      );
    case 'teaser-text-md':
      if (!article) return null;

      return (
        <TextStoryTeaserWidget
          variant="medium"
          className={className}
          article={article}
          hideSectionIcon={hideSectionIcon}
          isSplitLayout={isSplitLayout}
        />
      );
    case 'teaser-text-lg':
      if (!article) return null;

      return (
        <TextStoryTeaserWidget
          variant="large"
          className={className}
          article={article}
          hideSectionIcon={hideSectionIcon}
          isSplitLayout={isSplitLayout}
        />
      );
    case 'teaser-text-xl':
      if (!article) return null;

      return (
        <TextStoryTeaserWidget
          variant="x-large"
          className={className}
          article={article}
          hideSectionIcon={hideSectionIcon}
          isSplitLayout={isSplitLayout}
        />
      );
    case 'teaser-text-intro':
      if (!article) return null;

      return (
        <TextIntroStoryTeaserWidget
          variant="medium"
          className={className}
          article={article}
          hideSectionIcon={hideSectionIcon}
          isSplitLayout={isSplitLayout}
        />
      );
    case 'teaser-image-text-intro':
      if (!article) return null;

      return (
        <ImageTextIntroStoryTeaserWidget
          variant="medium"
          className={className}
          article={article}
          hideSectionIcon={hideSectionIcon}
          isSplitLayout={isSplitLayout}
        />
      );
    default:
      console.warn(
        `Story Teaser not found for this teaser type: ${teaser}. \n`,
        'Article:',
        article
      );

      return null;
  }
};

export default StoryTeaserSwitch;
