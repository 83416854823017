import { ArticleLink, FeatureArticlePageLink } from 'sharedTypes';
import generateArticleUrl from './generateArticleUrl';

const getTeaserLink = (
  article: ArticleLink | FeatureArticlePageLink
): string => {
  const isFeatureArticle = article._type === 'featureArticlePage';

  if (!isFeatureArticle) {
    return generateArticleUrl(article as ArticleLink);
  }

  if (article.teaserLink?.enabled && article.teaserLink?.url) {
    return article.teaserLink.url;
  }

  return `/feature/${article.slug}`;
};

export default getTeaserLink;
