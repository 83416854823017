import React from 'react';
import cx from 'classnames';

import sanityImgUtil from 'utils/sanityImgUtil';
import generateSectionUrl from 'utils/generateSectionUrl';

import { Button, Img } from 'components/base';
import Language from 'constants/Language';

import { SectionLink } from 'sharedTypes';
import { useIsFiction } from 'src/hooks/useFiction';
import FictionTag from './FictionTag';

interface Props {
  className?: string;
  section: SectionLink;
  imageColor?: 'dark' | 'light';
  hideIcon?: boolean;
  isFictionTeaser?: boolean;
}

const SectionButton: React.FC<Props> = ({
  className,
  section,
  imageColor = 'dark',
  hideIcon = false,
  isFictionTeaser = false,
}) => {
  const isFiction = useIsFiction();
  const logoImgColor = isFiction ? 'blue' : imageColor;

  if (isFictionTeaser) {
    return (
      <div className={cx('SectionButton', className)}>
        <div className="inline-flex pb_25">
          <FictionTag />
        </div>
      </div>
    );
  }

  if (hideIcon) {
    return null;
  }

  return (
    <div className={cx('SectionButton', className)}>
      <Button
        to={generateSectionUrl(section.slug)}
        ariaLabel={Language.t('Global.sectionButtonAriaLabel', {
          sectionTitle: section.title,
        })}
      >
        <div className="linked-border-bottom-red inline-flex pb_25">
          <Img
            alt={Language.t('Global.sectionIconAlt', {
              sectionTitle: section.title,
            })}
            src={sanityImgUtil(section.images[logoImgColor], 300)}
            dimensions={section.images[logoImgColor]?.metadata?.dimensions}
            className="SectionButton__image"
          />
        </div>
      </Button>
    </div>
  );
};

export default SectionButton;
