import sanityImgUtil from 'utils/sanityImgUtil';

import { Image } from 'sharedTypes';

const sanityImgSrcSetUtil = (image: Image, ...widths: number[]) => {
  if (!image) return '';

  const imagePaths = widths.map((w) => `${sanityImgUtil(image, w)} ${w}w`);

  return imagePaths.join(',');
};

export default sanityImgSrcSetUtil;
